<template>
  <header
    :data-theme="`${$store.state.themeslug}default`"
    class="header-v2 js-header-v2 shadow-sm"
    data-animation="on"
    data-animation-offset="400"
  >
    <div class="header-v2__wrapper">
      <div class="header-v2__container container max-width-lg">
        <div class="header-v2__logo" v-if="$route.name !== 'Info'">
          <router-link to="/">
            <figure>
              <img
                class="margin-x-auto block animation--off"
                width="auto"
                height="96"
                :src="$store.state.organisation.logo"
              />
            </figure>
          </router-link>
        </div>
        <div class="header-v2__logo" v-if="$route.name == 'Info'">
          <svg
            version="1.1"
            width="200"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 577.8 181.2"
            style="enable-background:new 0 0 577.8 181.2;"
            xml:space="preserve"
          >
            <g>
              <path
                d="M342.7,176.4c0,0.6,0.2,3,2.7,3c1.5,0,2.6-1,2.6-2.8c0-1.8-1.5-2.2-2.6-2.7c-2.2-0.8-2.7-1.1-3.5-1.8
		c-0.8-0.7-1.1-1.9-1.1-3.1c0-1.9,1.5-4.4,4.6-4.4c2.7,0,4.7,1.8,4.7,4.6h-2.4c0-1.8-1.3-2.4-2.3-2.4c-1.4,0-2.2,1.3-2.2,2.2
		c0,1.6,1.5,2,2.2,2.3c2.1,0.8,5,1.4,5,5.4c0,2.9-2,5-5,5c-2.3,0-5-1.5-5-5.2H342.7z"
              />
              <path d="M354.7,181.2h-2.3v-16.4h2.3V181.2z" />
              <path
                d="M360,167.5h-2.3v-2.7h2.3V167.5z M360,181.2h-2.3v-12.2h2.3V181.2z"
              />
              <path
                d="M379.2,181.2v-6.8c0-1-0.1-3.7-2.9-3.7c-2.9,0-2.9,2.6-2.9,3.6v6.8h-2.3v-6.8c0-1,0-1.7-0.5-2.5c-0.6-0.9-1.4-1.1-2.3-1.1
		c-3,0-3,2.7-3,3.6v6.8h-2.3v-12.2h2.1v1.3h0c0.4-0.5,1.2-1.7,3.5-1.7c1.5,0,3.2,0.7,3.8,2.1c0.4-0.6,1.3-2.1,4-2.1
		c2.1,0,3.5,0.8,4.3,2.2c0.4,0.8,0.7,1.8,0.7,3v7.4H379.2z"
              />
              <path
                d="M400.7,181.2v-6.8c0-1-0.1-3.7-2.9-3.7c-2.9,0-2.9,2.6-2.9,3.6v6.8h-2.3v-6.8c0-1,0-1.7-0.5-2.5c-0.6-0.9-1.4-1.1-2.3-1.1
		c-3,0-3,2.7-3,3.6v6.8h-2.3v-12.2h2.1v1.3h0c0.4-0.5,1.2-1.7,3.5-1.7c1.5,0,3.2,0.7,3.8,2.1c0.4-0.6,1.3-2.1,4-2.1
		c2.1,0,3.5,0.8,4.3,2.2c0.4,0.8,0.7,1.8,0.7,3v7.4H400.7z"
              />
              <path
                d="M407.6,176.1c0.6,2.4,2.5,3.3,4,3.3c1.7,0,2.9-1,3.5-2.1h2.3c-1.2,3.2-4,4.2-5.9,4.2c-3.5,0-6.2-3-6.2-6.5
		c0-3.7,2.8-6.4,6.3-6.4c3.3,0,6.2,2.7,6.2,6.3c0,0.4,0,1-0.1,1.2H407.6z M415.5,174.3c-0.1-1.8-1.7-3.6-4-3.6c-2.2,0-3.8,1.8-4,3.6
		H415.5z"
              />
              <path
                d="M420.1,181.2v-12.2h2.1v1.1h0c1-1.4,2.5-1.4,3.1-1.4v2.4c-0.4,0-1.1,0.2-1.7,0.4c-0.7,0.4-1.3,1.3-1.3,3.1v6.7H420.1z"
              />
              <path d="M435.6,181.2h-2.3v-16.4h2.3V181.2z" />
              <path
                d="M440.2,176.1c0.6,2.4,2.5,3.3,4,3.3c1.7,0,2.9-1,3.5-2.1h2.3c-1.2,3.2-4,4.2-5.9,4.2c-3.5,0-6.2-3-6.2-6.5
		c0-3.7,2.8-6.4,6.3-6.4c3.3,0,6.2,2.7,6.2,6.3c0,0.4,0,1-0.1,1.2H440.2z M448.2,174.3c-0.1-1.8-1.7-3.6-4-3.6c-2.2,0-3.8,1.8-4,3.6
		H448.2z"
              />
              <path
                d="M452.8,181.2v-12.2h2.1v1.1h0c1-1.4,2.5-1.4,3.1-1.4v2.4c-0.4,0-1.1,0.2-1.7,0.4c-0.7,0.4-1.3,1.3-1.3,3.1v6.7H452.8z"
              />
              <path
                d="M461.4,176.1c0.6,2.4,2.5,3.3,4,3.3c1.7,0,2.9-1,3.5-2.1h2.3c-1.2,3.2-4,4.2-5.9,4.2c-3.5,0-6.2-3-6.2-6.5
		c0-3.7,2.8-6.4,6.3-6.4c3.3,0,6.2,2.7,6.2,6.3c0,0.4,0,1-0.1,1.2H461.4z M469.3,174.3c-0.1-1.8-1.7-3.6-4-3.6c-2.2,0-3.8,1.8-4,3.6
		H469.3z"
              />
              <path
                d="M482.5,181.2v-6.7c0-1,0-3.8-3.1-3.8c-1.9,0-3.2,1.3-3.2,3.8v6.7h-2.3v-12.2h2.1v1.3h0c1.2-1.6,3.1-1.6,3.6-1.6
		c1.9,0,3.4,0.8,4.3,2.2c0.5,0.8,0.8,1.8,0.8,3.1v7.3H482.5z"
              />
            </g>
            <g>
              <path
                d="M75.7,55c5,0,9-4,9-9c0-5-4-9-9-9c-5,0-9,4-9,9C66.6,51,70.7,55,75.7,55z"
              />
              <path
                d="M30,98c5,0,9-4,9-9c0-5-4-9-9-9c-5,0-9,4-9,9C21,94,25,98,30,98z"
              />
              <path
                d="M5.6,71.4C2.5,71.4,0,73.9,0,77c0,3.1,2.5,5.6,5.6,5.6c3.1,0,5.6-2.5,5.6-5.6C11.3,73.9,8.7,71.4,5.6,71.4z"
              />
              <path
                d="M31.5,50.2c3.1,0,5.6-2.5,5.6-5.6c0-3.1-2.5-5.6-5.6-5.6s-5.6,2.5-5.6,5.6C25.8,47.6,28.4,50.2,31.5,50.2z"
              />
              <path
                d="M99.6,25.8c3.1,0,5.6-2.5,5.6-5.6s-2.5-5.6-5.6-5.6c-3.1,0-5.6,2.5-5.6,5.6S96.5,25.8,99.6,25.8z"
              />
              <path
                d="M5.6,55.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8c-1.6,0-2.8,1.3-2.8,2.8C2.8,54.5,4.1,55.8,5.6,55.8z"
              />
              <path
                d="M72.8,23c1.6,0,2.8-1.3,2.8-2.8s-1.3-2.8-2.8-2.8c-1.6,0-2.8,1.3-2.8,2.8S71.3,23,72.8,23z"
              />
              <path
                d="M14.1,28.4c-1.6,0-2.8,1.3-2.8,2.8c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8C16.9,29.7,15.6,28.4,14.1,28.4z"
              />
              <path
                d="M39,5.6c1.6,0,2.8-1.3,2.8-2.8C41.8,1.3,40.6,0,39,0c-1.6,0-2.8,1.3-2.8,2.8C36.2,4.4,37.4,5.6,39,5.6z"
              />
              <path
                d="M81.9,11.3c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8c-1.6,0-2.8,1.3-2.8,2.8C79,10,80.3,11.3,81.9,11.3z"
              />
              <g>
                <path
                  d="M99.6,103c-11.1,0-20.2,9-20.2,20.2c0,11.1,9,20.2,20.2,20.2c11.1,0,20.2-9,20.2-20.2C119.8,112,110.7,103,99.6,103z"
                />
                <path
                  d="M109.9,46l2.3,0.4c-0.4-0.1-1.7-0.5-2-2.3c0,0.1,0,0.2,0,0.3c0-0.2,0-0.4,0-0.6c0-5.4-4.4-9.7-9.7-9.7
			c-5.4,0-9.7,4.4-9.7,9.7c0,0.6,0.1,1.2,0.2,1.8c0,0,0,0,0,0c0,0.4,0.1,0.9,0.1,1.3c0,8.3-6.7,15-15,15c-5.3,0-9.9-2.7-12.6-6.8
			c-2.8-3.5-7.2-5.7-12-5.7c-8.6,0-15.5,7-15.5,15.5c0,5.1,2.5,9.6,6.3,12.5c0,0,0,0,0,0c3.3,2.8,5.4,6.9,5.4,11.5
			c0,7.7-5.8,14-13.3,14.9c-0.4-0.1-0.9-0.1-1.3-0.1c-5.4,0-9.7,4.4-9.7,9.7c0,5.3,4.2,9.6,9.5,9.7c-0.1,0-0.3,0-0.4,0
			c0,0,1.5,0,2.1,1.7c-0.3-1.4-0.4-1.8-0.4-1.8c5.7,30.6,32.5,53.7,64.7,53.7c36.4,0,65.9-29.5,65.9-65.9
			C164.7,78.4,141,51.3,109.9,46z M99.6,158.5c-18.6,0-33.8-14.3-35.1-32.5h-0.1V71.4h15.1v22.9c5.7-4,12.7-6.4,20.2-6.4
			c19.5,0,35.3,15.8,35.3,35.3S119,158.5,99.6,158.5z"
                />
              </g>
            </g>
            <g>
              <path
                d="M237.6,87.9c-7.5,0-14.4,2.4-20.2,6.4V71.4h-15v54.4h0.1c1.4,18.1,16.5,32.4,35,32.4c19.4,0,35.2-15.7,35.2-35.2
		C272.8,103.7,257.1,87.9,237.6,87.9z M237.6,143.2c-11.1,0-20.1-9-20.1-20.1s9-20.1,20.1-20.1c11.1,0,20.1,9,20.1,20.1
		S248.7,143.2,237.6,143.2z"
              />
              <path
                d="M321.2,88l0,37.1c0,11.9-5.4,17.4-14.2,17.9c-8.8-0.5-14.2-6-14.2-17.9l0-37.1h-15.3l0.2,36.7c0,13.9,3.4,21.6,9.1,26.6
		c5,4.4,11.9,6.9,19.8,6.9c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c7.9,0,14.8-2.5,19.8-6.9c5.8-5,9.1-12.8,9.1-26.6l0.2-36.7H321.2z"
              />
              <path
                d="M482.8,122.8v-3.6c-1.1-6.8-2.6-9.7-2.6-9.7c0,0-1.7-4-3.6-6.5c-1.3-1.7-3.5-4.3-3.5-4.3c-1.5-1.6-2.1-2.1-4-3.5l-1.3-0.9
		v0c-5.9-4.1-12.9-6.3-20.1-6.3c-12,0-22.8,6-29.1,15.5l0,0c-2,3-3.6,6.4-4.6,10l17.5,21.4c0,0,0,0,0-0.1l-17.4-21.3
		c1-3.6,2.6-7,4.6-10l0,0c0,0,0,0,0,0l10.3,12.6c2.8-7.7,10.2-13.1,18.8-13.1c11.1,0,20.1,9,20.1,20.1s-9,20.1-20.1,20.1
		c-2.5,0-4.9-0.5-7.1-1.3c-3.3-1.2-6.1-3.3-8.4-5.9l-0.3-0.4l-0.6-0.7l-17.5-21.3l-0.8-1l0,0l-8.4-10.3c0,0,0,0,0,0l-1.6-1.9
		c-1.5-1.7-3.2-3.3-5-4.7l-0.1-0.1v0c-0.8-0.6-1.6-1.2-2.5-1.8c-4.2-2.7-8.9-4.6-14-5.3c-0.4-0.1-0.7-0.1-1.1-0.1
		c-0.4,0-0.7-0.1-1.1-0.1c0,0-0.1,0-0.1,0c-0.4,0-0.8-0.1-1.3-0.1c-0.4,0-0.9,0-1.3,0c-9.5,0-18.3,3.8-24.7,10.2
		c-0.3,0.3-0.7,0.7-1,1c-3.9,4.2-6.8,9.3-8.3,15.2c-0.7,2.6-1,5.2-1.1,7.8c0,0.3,0,0.6,0,0.9c0,19.3,15.7,35.1,35.1,35.1
		c0.4,0,0.9,0,1.3,0c0.4,0,0.9,0,1.3-0.1c0,0,0,0,0,0c0.4,0,0.9-0.1,1.3-0.1c0,0,0.1,0,0.1,0c0.9-0.1,1.7-0.2,2.6-0.4
		c0.4-0.1,0.9-0.2,1.3-0.3c0.4-0.1,0.9-0.2,1.3-0.3c0.9-0.2,1.7-0.5,2.5-0.8c0.4-0.1,0.8-0.3,1.2-0.5c0.4-0.2,0.8-0.3,1.2-0.5
		c0.4-0.2,0.8-0.4,1.2-0.6c0.4-0.2,0.8-0.4,1.2-0.6c0.4-0.2,0.8-0.4,1.2-0.7c0.4-0.2,0.7-0.4,1.1-0.7c0.3-0.2,0.6-0.4,0.9-0.6
		c3.6-2.5,6.8-5.6,9.3-9.3l-10.3-12.6v0c0,0,0-0.1,0-0.1c-2.9,7.6-10.2,13-18.8,13c-9.4,0-17.4-6.4-19.5-15.5
		c-0.4-1.5-0.5-3.1-0.5-4.6c0-0.7,0-1.4,0.1-2.1l0-0.1c1.1-10.2,9.7-17.9,20-17.9c2.2,0,4.3,0.4,6.2,1c3.4,1.1,6.4,3.1,8.7,5.7
		c0.5,0.6,1,1.2,1.4,1.8l0.1,0.1c0.2,0.3,0.4,0.6,0.6,1l16.7,20.2c-0.1,0.4-0.3,1-0.4,1.4c0.2-0.5,0.3-0.9,0.4-1.4l1.3,1.6l7.9,9.6
		c2.4,3.2,5.2,5.9,8.5,8.2c5.6,3.8,12.4,6,19.6,6c18.3,0,33.7-14.2,35.1-32.4l0-0.1v-1.9c0-0.2,0-0.5,0-0.7
		C482.8,123,482.8,122.9,482.8,122.8z"
              />
              <path
                d="M396.6,123.3c0,2.5-0.4,4.8-1.3,7v0l10.3,12.5c2-3,3.7-6.5,4.7-10l-17.1-20.9C395.4,115.2,396.6,119,396.6,123.3z"
              />
              <path
                d="M418.5,103.6C418.5,103.6,418.6,103.6,418.5,103.6L418.5,103.6c-2,3-3.6,6.3-4.6,10l17.5,21.4c-1.5-2.1-2.6-4.5-3.2-7.1
		c-0.4-1.5-0.5-3-0.5-4.6c0-0.7,0-1.4,0.1-2.1l0-0.1c0.2-1.7,0.6-3.3,1.1-4.8c0,0,0,0,0,0L418.5,103.6z"
              />
              <path
                d="M577.8,124.8c0-0.6,0-1.1,0-1.7c0-19.4-15.7-35.2-35.2-35.2c-19.4,0-35.2,15.7-35.2,35.2c0,19.4,15.7,35.2,35.2,35.2
		c15.1,0,28-9.6,33-23h-17.3c-3.7,4.6-9.3,7.6-15.7,7.6c-10.4,0-19-8-19.9-18.1h39.8c0,0,0,0,0,0H577.8z M542.7,103
		c8,0,14.8,4.6,18,11.4h-36.1C527.9,107.6,534.7,103,542.7,103z"
              />
              <g>
                <rect x="487.5" y="88" width="15.2" height="70.1" />
                <rect x="487.5" y="71.5" width="15.2" height="9.8" />
              </g>
              <path
                d="M397.3,90.6c0.9,0.6,1.7,1.2,2.5,1.8v0l0.1,0.1c1.8,1.4,3.5,3,5,4.7l1.6,1.9c0,0,0,0,0,0l5.6,6.9V71.4H397l0,18.9
		C397,90.4,397.2,90.5,397.3,90.6z"
              />
              <path
                d="M470.7,92.5L470.7,92.5l1.3,0.9c1.8,1.4,2.4,1.9,4,3.5c0,0,2.2,2.6,3.5,4.3c1.3,1.7,2.6,4.2,3.2,5.5c0-2.6,0-4.8,0-4.8
		l0-30.5h-15.1v19.1C468.7,91.2,469.7,91.8,470.7,92.5z"
              />
            </g>
          </svg>
        </div>

        <!-- <button
          class="header-v2__nav-control reset anim-menu-btn js-anim-menu-btn js-tab-focus"
          aria-label="Toggle menu"
        >
          <i
            class="anim-menu-btn__icon anim-menu-btn__icon--close"
            aria-hidden="true"
          ></i>
        </button> -->

        <nav class="nav-v2" role="navigation">
          <ul class="nav-v2__list nav-v2__list--main"></ul>

          <ul class="nav-v2__list nav-v2__list--main">
            <li
              class="nav-v2__item nav-v2__item--main"
              v-if="$route.name !== 'Info'"
            >
              <router-link to="/favorieten" class="btn btn--primary"
                >Favorieten
                <span
                  v-if="hasFavorites"
                  class="counter counter--critical margin-left-xxs"
                  >{{ favCount }} <i class="sr-only">Notifications</i></span
                ></router-link
              >
            </li>

            <li
              class="nav-v2__item nav-v2__item--main"
              v-if="$route.name == 'Info'"
            >
              <router-link to="/login" class="btn btn--primary"
                >Inloggen
              </router-link>
            </li>
            <li
              class="nav-v2__item nav-v2__item--main"
              v-if="$route.name == 'Info'"
            >
              <a href="https://www.educared.nl/kennismaken" class="no-underline" target="_blank"
                ><svg class="icon align-middle" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><circle cx="286" cy="136" r="15"/><circle cx="346" cy="136" r="15"/><circle cx="406" cy="136" r="15"/><path d="m337.213 481c24.695 0 44.787-20.187 44.787-45v-60c0-6.453-4.127-12.182-10.246-14.227l-89.787-30c-4.396-1.468-9.219-.823-13.074 1.746l-38.183 25.455c-40.978-19.444-89.411-67.448-108.675-107.698l25.446-38.169c2.569-3.854 3.214-8.68 1.746-13.074l-30-89.787c-2.045-6.12-7.774-10.246-14.227-10.246h-60c-24.813 0-45 20.091-45 44.787 0 82.877 37.684 167.936 103.39 233.367 65.634 65.36 150.86 102.846 233.823 102.846zm-292.213-351h49.197l24.25 72.576-25.927 38.89c-2.74 4.11-3.281 9.305-1.446 13.891 9.975 24.937 29.937 52.984 56.208 78.975 26.098 25.819 54.282 45.563 79.361 55.594 4.585 1.834 9.782 1.293 13.891-1.446l38.891-25.927 72.575 24.25v49.197c0 8.411-6.495 15-14.787 15-157.913 0-307.213-148.814-307.213-306.213 0-8.292 6.589-14.787 15-14.787z"/><path d="m280.26 299.858c5.604 2.322 12.057 1.038 16.347-3.251l55.606-55.607h53.787c58.448 0 106-47.103 106-105s-47.552-105-106-105h-120c-57.897 0-105 47.103-105 105 0 52.805 39.183 96.631 90 103.932v46.068c0 6.067 3.655 11.536 9.26 13.858zm-69.26-163.858c0-41.355 33.645-75 75-75h120c41.906 0 76 33.645 76 75s-34.094 75-76 75h-60c-3.979 0-7.794 1.58-10.606 4.393l-34.394 34.394v-23.787c0-8.284-6.716-15-15-15-41.355 0-75-33.645-75-75z"/></g></svg>
 Direct contact
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Nav",

  mounted() {
    let frontEnd = document.createElement("script");
    frontEnd.setAttribute("src", "/scripts/nav.js");
    document.body.appendChild(frontEnd);
  },
  computed: {
    hasFavorites() {
      return this.$store.state.favorites.length > 0;
    },
    favCount() {
      return this.$store.state.favorites.length;
    },
  },
};
</script>
