<template>
  <fragment>
    <Feature />




    <section class="gallery-v2 padding-y-xl">
      <div class="container max-width-adaptive-lg clearfix">
        <div class="margin-bottom-xl clearfix">
          <div class="grid gap-sm">
            <div class="col-6@lg offset-3@lg">
              <div class="text-component line-height-lg">
                <h1 class="color-primary">
                  Een toegankelijk en gebruiksvriendelijk platform<span
                    class="color-accent"
                    >.</span
                  >
                </h1>

                <p>
                  Gebruikers krijgen snel en makkelijk toegang tot kennis en
                  informatie over praktische zorg gerelateerde handelingen en
                  zelfredzaamheid.
                </p>
                <ul class="list">
                  <li>
                    <svg
                      version="1.1"
                      class="icon align-middle color-accent margin-right-xs"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512 512"
                      style="enable-background:new 0 0 512 512;"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M497.36,69.995c-7.532-7.545-19.753-7.558-27.285-0.032L238.582,300.845l-83.522-90.713	c-7.217-7.834-19.419-8.342-27.266-1.126c-7.841,7.217-8.343,19.425-1.126,27.266l97.126,105.481	c3.557,3.866,8.535,6.111,13.784,6.22c0.141,0.006,0.277,0.006,0.412,0.006c5.101,0,10.008-2.026,13.623-5.628L497.322,97.286	C504.873,89.761,504.886,77.54,497.36,69.995z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M492.703,236.703c-10.658,0-19.296,8.638-19.296,19.297c0,119.883-97.524,217.407-217.407,217.407	c-119.876,0-217.407-97.524-217.407-217.407c0-119.876,97.531-217.407,217.407-217.407c10.658,0,19.297-8.638,19.297-19.296	C275.297,8.638,266.658,0,256,0C114.84,0,0,114.84,0,256c0,141.154,114.84,256,256,256c141.154,0,256-114.846,256-256	C512,245.342,503.362,236.703,492.703,236.703z"
                          ></path>
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>Geen extra inlog voor gebruikers
                  </li>
                  <li>
                    <svg
                      version="1.1"
                      class="icon align-middle color-accent margin-right-xs"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512 512"
                      style="enable-background:new 0 0 512 512;"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M497.36,69.995c-7.532-7.545-19.753-7.558-27.285-0.032L238.582,300.845l-83.522-90.713	c-7.217-7.834-19.419-8.342-27.266-1.126c-7.841,7.217-8.343,19.425-1.126,27.266l97.126,105.481	c3.557,3.866,8.535,6.111,13.784,6.22c0.141,0.006,0.277,0.006,0.412,0.006c5.101,0,10.008-2.026,13.623-5.628L497.322,97.286	C504.873,89.761,504.886,77.54,497.36,69.995z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M492.703,236.703c-10.658,0-19.296,8.638-19.296,19.297c0,119.883-97.524,217.407-217.407,217.407	c-119.876,0-217.407-97.524-217.407-217.407c0-119.876,97.531-217.407,217.407-217.407c10.658,0,19.297-8.638,19.297-19.296	C275.297,8.638,266.658,0,256,0C114.84,0,0,114.84,0,256c0,141.154,114.84,256,256,256c141.154,0,256-114.846,256-256	C512,245.342,503.362,236.703,492.703,236.703z"
                          ></path>
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g></svg
                    >Content is op maat samen te stellen door organisatie
                  </li>
                  <li>
                    <svg
                      version="1.1"
                      class="icon align-middle color-accent margin-right-xs"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512 512"
                      style="enable-background:new 0 0 512 512;"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M497.36,69.995c-7.532-7.545-19.753-7.558-27.285-0.032L238.582,300.845l-83.522-90.713	c-7.217-7.834-19.419-8.342-27.266-1.126c-7.841,7.217-8.343,19.425-1.126,27.266l97.126,105.481	c3.557,3.866,8.535,6.111,13.784,6.22c0.141,0.006,0.277,0.006,0.412,0.006c5.101,0,10.008-2.026,13.623-5.628L497.322,97.286	C504.873,89.761,504.886,77.54,497.36,69.995z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M492.703,236.703c-10.658,0-19.296,8.638-19.296,19.297c0,119.883-97.524,217.407-217.407,217.407	c-119.876,0-217.407-97.524-217.407-217.407c0-119.876,97.531-217.407,217.407-217.407c10.658,0,19.297-8.638,19.297-19.296	C275.297,8.638,266.658,0,256,0C114.84,0,0,114.84,0,256c0,141.154,114.84,256,256,256c141.154,0,256-114.846,256-256	C512,245.342,503.362,236.703,492.703,236.703z"
                          ></path>
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g></svg
                    >Organisaties kunnen ook eigen content toevoegen
                  </li>
                  <li>
                    <svg
                      version="1.1"
                      class="icon align-middle color-accent margin-right-xs"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512 512"
                      style="enable-background:new 0 0 512 512;"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M497.36,69.995c-7.532-7.545-19.753-7.558-27.285-0.032L238.582,300.845l-83.522-90.713	c-7.217-7.834-19.419-8.342-27.266-1.126c-7.841,7.217-8.343,19.425-1.126,27.266l97.126,105.481	c3.557,3.866,8.535,6.111,13.784,6.22c0.141,0.006,0.277,0.006,0.412,0.006c5.101,0,10.008-2.026,13.623-5.628L497.322,97.286	C504.873,89.761,504.886,77.54,497.36,69.995z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M492.703,236.703c-10.658,0-19.296,8.638-19.296,19.297c0,119.883-97.524,217.407-217.407,217.407	c-119.876,0-217.407-97.524-217.407-217.407c0-119.876,97.531-217.407,217.407-217.407c10.658,0,19.297-8.638,19.297-19.296	C275.297,8.638,266.658,0,256,0C114.84,0,0,114.84,0,256c0,141.154,114.84,256,256,256c141.154,0,256-114.846,256-256	C512,245.342,503.362,236.703,492.703,236.703z"
                          ></path>
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g></svg
                    >Eigen look and feel (maatwerk)
                  </li>
                  <li>
                    <svg
                      version="1.1"
                      class="icon align-middle color-accent margin-right-xs"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512 512"
                      style="enable-background:new 0 0 512 512;"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M497.36,69.995c-7.532-7.545-19.753-7.558-27.285-0.032L238.582,300.845l-83.522-90.713	c-7.217-7.834-19.419-8.342-27.266-1.126c-7.841,7.217-8.343,19.425-1.126,27.266l97.126,105.481	c3.557,3.866,8.535,6.111,13.784,6.22c0.141,0.006,0.277,0.006,0.412,0.006c5.101,0,10.008-2.026,13.623-5.628L497.322,97.286	C504.873,89.761,504.886,77.54,497.36,69.995z"
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M492.703,236.703c-10.658,0-19.296,8.638-19.296,19.297c0,119.883-97.524,217.407-217.407,217.407	c-119.876,0-217.407-97.524-217.407-217.407c0-119.876,97.531-217.407,217.407-217.407c10.658,0,19.297-8.638,19.297-19.296	C275.297,8.638,266.658,0,256,0C114.84,0,0,114.84,0,256c0,141.154,114.84,256,256,256c141.154,0,256-114.846,256-256	C512,245.342,503.362,236.703,492.703,236.703z"
                          ></path>
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g></svg
                    >Ook geschikt voor tablets, telefoon
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <ul class="gallery-v2__grid grid gap-sm">
          <li class="gallery-v2__item col-6@sm col-4@lg">
            <figure>
              <img src="@/assets/buddie_1.png" alt="Image description" />
              <figcaption
                class="padding-x-sm padding-top-md padding-bottom-sm text-center color-accent"
              >Het beginscherm in Buddie</figcaption>
            </figure>
          </li>

          <li class="gallery-v2__item col-6@sm col-4@lg">
            <figure>
              <img src="@/assets/buddie_2.png" alt="Image description" />
              <figcaption
                class="padding-x-sm padding-top-md padding-bottom-sm text-center color-accent"
              >Het zoekscherm in de leeromgeving</figcaption>
            </figure>
          </li>

          <li class="gallery-v2__item col-6@sm col-4@lg">
            <figure>
              <img src="@/assets/buddie_3.png" alt="Image description" />
      <figcaption
                class="padding-x-sm padding-top-md padding-bottom-sm text-center color-accent"
              >Voorbeeld van een video in de leeromgeving</figcaption>
            </figure>
          </li>
        </ul>
      </div>

  <figure class="bg-decoration-v2 z-index-1" aria-hidden="true">
    <svg class="bg-decoration-v2__svg color-accent opacity-10%" viewBox="0 0 1920 450" fill="none">
      <g opacity="1" fill="currentColor" stroke="none">
        <circle cx="1544" cy="287" r="2" />
        <circle cx="1544" cy="303" r="2" />
        <circle cx="1544" cy="319" r="2" />
        <circle cx="1544" cy="335" r="2" />
        <circle cx="1544" cy="351" r="2" />
        <circle cx="1544" cy="367" r="2" />
        <circle cx="1544" cy="383" r="2" />
        <circle cx="1544" cy="399" r="2" />
        <circle cx="1544" cy="415" r="2" />
        <circle cx="1544" cy="431" r="2" />
        <circle cx="1560" cy="287" r="2" />
        <circle cx="1560" cy="303" r="2" />
        <circle cx="1560" cy="319" r="2" />
        <circle cx="1560" cy="335" r="2" />
        <circle cx="1560" cy="351" r="2" />
        <circle cx="1560" cy="367" r="2" />
        <circle cx="1560" cy="383" r="2" />
        <circle cx="1560" cy="399" r="2" />
        <circle cx="1560" cy="415" r="2" />
        <circle cx="1560" cy="431" r="2" />
        <circle cx="1576" cy="287" r="2" />
        <circle cx="1576" cy="303" r="2" />
        <circle cx="1576" cy="319" r="2" />
        <circle cx="1576" cy="335" r="2" />
        <circle cx="1576" cy="351" r="2" />
        <circle cx="1576" cy="367" r="2" />
        <circle cx="1576" cy="383" r="2" />
        <circle cx="1576" cy="399" r="2" />
        <circle cx="1576" cy="415" r="2" />
        <circle cx="1576" cy="431" r="2" />
        <circle cx="1592" cy="287" r="2" />
        <circle cx="1592" cy="303" r="2" />
        <circle cx="1592" cy="319" r="2" />
        <circle cx="1592" cy="335" r="2" />
        <circle cx="1592" cy="351" r="2" />
        <circle cx="1592" cy="367" r="2" />
        <circle cx="1592" cy="383" r="2" />
        <circle cx="1592" cy="399" r="2" />
        <circle cx="1592" cy="415" r="2" />
        <circle cx="1592" cy="431" r="2" />
        <circle cx="1608" cy="287" r="2" />
        <circle cx="1624" cy="287" r="2" />
        <circle cx="1640" cy="287" r="2" />
        <circle cx="1656" cy="287" r="2" />
        <circle cx="1672" cy="287" r="2" />
        <circle cx="1688" cy="287" r="2" />
        <circle cx="1608" cy="303" r="2" />
        <circle cx="1624" cy="303" r="2" />
        <circle cx="1640" cy="303" r="2" />
        <circle cx="1656" cy="303" r="2" />
        <circle cx="1672" cy="303" r="2" />
        <circle cx="1688" cy="303" r="2" />
        <circle cx="1608" cy="319" r="2" />
        <circle cx="1624" cy="319" r="2" />
        <circle cx="1640" cy="319" r="2" />
        <circle cx="1656" cy="319" r="2" />
        <circle cx="1672" cy="319" r="2" />
        <circle cx="1688" cy="319" r="2" />
        <circle cx="1608" cy="335" r="2" />
        <circle cx="1624" cy="335" r="2" />
        <circle cx="1640" cy="335" r="2" />
        <circle cx="1656" cy="335" r="2" />
        <circle cx="1672" cy="335" r="2" />
        <circle cx="1688" cy="335" r="2" />
        <circle cx="1608" cy="351" r="2" />
        <circle cx="1608" cy="367" r="2" />
        <circle cx="1608" cy="383" r="2" />
        <circle cx="1608" cy="399" r="2" />
        <circle cx="1608" cy="415" r="2" />
        <circle cx="1608" cy="431" r="2" />
        <circle cx="1624" cy="351" r="2" />
        <circle cx="1624" cy="367" r="2" />
        <circle cx="1624" cy="383" r="2" />
        <circle cx="1624" cy="399" r="2" />
        <circle cx="1624" cy="415" r="2" />
        <circle cx="1624" cy="431" r="2" />
        <circle cx="1640" cy="351" r="2" />
        <circle cx="1656" cy="351" r="2" />
        <circle cx="1672" cy="351" r="2" />
        <circle cx="1688" cy="351" r="2" />
        <circle cx="1640" cy="367" r="2" />
        <circle cx="1656" cy="367" r="2" />
        <circle cx="1672" cy="367" r="2" />
        <circle cx="1688" cy="367" r="2" />
        <circle cx="1640" cy="383" r="2" />
        <circle cx="1640" cy="399" r="2" />
        <circle cx="1640" cy="415" r="2" />
        <circle cx="1640" cy="431" r="2" />
        <circle cx="1656" cy="383" r="2" />
        <circle cx="1672" cy="383" r="2" />
        <circle cx="1688" cy="383" r="2" />
        <circle cx="1656" cy="399" r="2" />
        <circle cx="1656" cy="415" r="2" />
        <circle cx="1656" cy="431" r="2" />
        <circle cx="1672" cy="399" r="2" />
        <circle cx="1672" cy="415" r="2" />
        <circle cx="1672" cy="431" r="2" />
        <circle cx="1688" cy="399" r="2" />
        <circle cx="1688" cy="415" r="2" />
        <circle cx="1688" cy="431" r="2" />
        <circle cx="232" cy="37" r="2" />
        <circle cx="232" cy="53" r="2" />
        <circle cx="232" cy="69" r="2" />
        <circle cx="232" cy="85" r="2" />
        <circle cx="232" cy="101" r="2" />
        <circle cx="232" cy="117" r="2" />
        <circle cx="232" cy="133" r="2" />
        <circle cx="232" cy="149" r="2" />
        <circle cx="232" cy="165" r="2" />
        <circle cx="232" cy="181" r="2" />
        <circle cx="248" cy="37" r="2" />
        <circle cx="248" cy="53" r="2" />
        <circle cx="248" cy="69" r="2" />
        <circle cx="248" cy="85" r="2" />
        <circle cx="248" cy="101" r="2" />
        <circle cx="248" cy="117" r="2" />
        <circle cx="248" cy="133" r="2" />
        <circle cx="248" cy="149" r="2" />
        <circle cx="248" cy="165" r="2" />
        <circle cx="248" cy="181" r="2" />
        <circle cx="264" cy="37" r="2" />
        <circle cx="264" cy="53" r="2" />
        <circle cx="264" cy="69" r="2" />
        <circle cx="264" cy="85" r="2" />
        <circle cx="264" cy="101" r="2" />
        <circle cx="264" cy="117" r="2" />
        <circle cx="264" cy="133" r="2" />
        <circle cx="264" cy="149" r="2" />
        <circle cx="264" cy="165" r="2" />
        <circle cx="264" cy="181" r="2" />
        <circle cx="280" cy="37" r="2" />
        <circle cx="280" cy="53" r="2" />
        <circle cx="280" cy="69" r="2" />
        <circle cx="280" cy="85" r="2" />
        <circle cx="280" cy="101" r="2" />
        <circle cx="280" cy="117" r="2" />
        <circle cx="280" cy="133" r="2" />
        <circle cx="280" cy="149" r="2" />
        <circle cx="280" cy="165" r="2" />
        <circle cx="280" cy="181" r="2" />
        <circle cx="296" cy="37" r="2" />
        <circle cx="312" cy="37" r="2" />
        <circle cx="328" cy="37" r="2" />
        <circle cx="344" cy="37" r="2" />
        <circle cx="360" cy="37" r="2" />
        <circle cx="376" cy="37" r="2" />
        <circle cx="296" cy="53" r="2" />
        <circle cx="312" cy="53" r="2" />
        <circle cx="328" cy="53" r="2" />
        <circle cx="344" cy="53" r="2" />
        <circle cx="360" cy="53" r="2" />
        <circle cx="376" cy="53" r="2" />
        <circle cx="296" cy="69" r="2" />
        <circle cx="312" cy="69" r="2" />
        <circle cx="328" cy="69" r="2" />
        <circle cx="344" cy="69" r="2" />
        <circle cx="360" cy="69" r="2" />
        <circle cx="376" cy="69" r="2" />
        <circle cx="296" cy="85" r="2" />
        <circle cx="312" cy="85" r="2" />
        <circle cx="328" cy="85" r="2" />
        <circle cx="344" cy="85" r="2" />
        <circle cx="360" cy="85" r="2" />
        <circle cx="376" cy="85" r="2" />
        <circle cx="296" cy="101" r="2" />
        <circle cx="296" cy="117" r="2" />
        <circle cx="296" cy="133" r="2" />
        <circle cx="296" cy="149" r="2" />
        <circle cx="296" cy="165" r="2" />
        <circle cx="296" cy="181" r="2" />
        <circle cx="312" cy="101" r="2" />
        <circle cx="312" cy="117" r="2" />
        <circle cx="312" cy="133" r="2" />
        <circle cx="312" cy="149" r="2" />
        <circle cx="312" cy="165" r="2" />
        <circle cx="312" cy="181" r="2" />
        <circle cx="328" cy="101" r="2" />
        <circle cx="344" cy="101" r="2" />
        <circle cx="360" cy="101" r="2" />
        <circle cx="376" cy="101" r="2" />
        <circle cx="328" cy="117" r="2" />
        <circle cx="344" cy="117" r="2" />
        <circle cx="360" cy="117" r="2" />
        <circle cx="376" cy="117" r="2" />
        <circle cx="328" cy="133" r="2" />
        <circle cx="328" cy="149" r="2" />
        <circle cx="328" cy="165" r="2" />
        <circle cx="328" cy="181" r="2" />
        <circle cx="344" cy="133" r="2" />
        <circle cx="360" cy="133" r="2" />
        <circle cx="376" cy="133" r="2" />
        <circle cx="344" cy="149" r="2" />
        <circle cx="344" cy="165" r="2" />
        <circle cx="344" cy="181" r="2" />
        <circle cx="360" cy="149" r="2" />
        <circle cx="360" cy="165" r="2" />
        <circle cx="360" cy="181" r="2" />
        <circle cx="376" cy="149" r="2" />
        <circle cx="376" cy="165" r="2" />
        <circle cx="376" cy="181" r="2" />
      </g>
      <g stroke="currentColor" stroke-width="2">
        <rect x="1568" y="83" width="244" height="244" />
        <rect x="124" y="-135" width="244" height="244" />
      </g>
    </svg>
  </figure>

    </section>
  </fragment>
</template>

<script>
import Feature from "@/components/Feature";

export default {
  name: "Info",
  components: {
    Feature,
  },
};
</script>
