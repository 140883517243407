<template>
  <fragment>
    <Search />
    <section :data-theme="`${$store.state.themeslug}soft`" class="padding-y-lg">
      <div class="container max-width-lg">
        <div class="grid grid-gap-sm">
          <div class="col-12">
            <div class="text-component margin-y-sm">
              <h4 class="color-primary">Gevonden items voor: {{ $route.params.query }} <span class="counter margin-left-xxs">{{ items.length }} </span></h4>
            </div>
          </div>

          <div :key="item.id" v-for="item in items" class="col-6 col-3@md">
            <ContentCard :uid="item.id" :data="item" />
          </div>
        </div>
      </div>
    </section>
  </fragment>
</template>

<script>
// @ is an alias to /src
import Search from "@/components/Search.vue";
import ContentCard from "@/components/ContentCard.vue";
import { uniqBy } from 'lodash';

export default {
  name: "Zoeken",
  components: {
    Search,
    ContentCard,
  },
  computed: {
    items() {
      if (!this.$store.state.searchIndex) return;

      let results = "";
      const resultsArray = this.$store.state.searchIndex.search(
        this.$route.params.query,
        {
          fields: {
            title: { boost: 50 },
            search_meta: { boost: 1 },
          },
          // bool: "OR",
          expand: true,
        }
      );

      results = this.$store.state.content.filter((contentItem) =>
        resultsArray.some((result) => contentItem.id === result.ref)
      );

      return uniqBy(results, "title");
    },
  },
};
</script>
