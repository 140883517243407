<template>
  <figure>
    <img
      class="block width-100% card-img-top"
      loading="lazy"
      :data-src="src"
      :src="src"
      alt="Image description"
    />
    <noscript>
      <img :src="src" alt="Image description" />
    </noscript>
  </figure>
</template>

<script>
export default {
  name: "Img",
  props: {
    url: String,
  },
  mounted() {
    let frontEnd = document.createElement("script");
    frontEnd.setAttribute("src", "/scripts/lazy-img.js");
    document.body.appendChild(frontEnd);
  },
  computed: {
    src() {
      return this.$props.url;
    },
  },
};
</script>
