<template>
  <div class="card__container padding-xs">
    <router-link :to="{ name: 'Themaoverzicht', params: {slug: slug, title: $props.thema.title } }"  class="no-underline">
      <div class="card__img-wrapper">
        <img
          loading="lazy"
          :src="`https://wsrv.nl?url=${$props.thema.image}&w=390&h=160&fit=cover&a=attention`"
          class="card-img-top"
        />
      </div>
      <div class="position-relative">
        <span class="card__type-indicator padding-x-sm padding-y-xs"
          ><i class="icon-thema"></i><strong> Thema</strong></span
        >
      </div>

      <h5
        class="card-title card-title--styled margin-y-xs font-bold no-underline"
      >
        {{ $props.thema.title }}
      </h5>
    </router-link>
  </div>
</template>

<script>
import slugify from 'slugify';

export default {
  name: "ThemaCard",
  props: {
    thema: Object
  },
  mounted() {

  },
  computed: {
    slug(){
      return slugify(this.$props.thema.title, {lower: true});
    }
  }
};
</script>
