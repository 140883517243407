<template>
  <section :data-theme="`${$store.state.themeslug}soft`" class="padding-y-lg">
    <div class="container max-width-lg">
      <div class="grid grid-gap-sm">
        <div class="col-12">
          <div class="text-component margin-y-sm">
            <h4 class="color-primary">Actuele zelfzorg thema's</h4>
          </div>
        </div>

        <div :key="thema.id" v-for="thema in themas" class="col-6 col-4@md">
            <ThemaCard :thema="thema" />
        </div>


      </div>
    </div>
  </section>
</template>


<script>
import ThemaCard from '@/components/ThemaCard';

export default {
  name: "Themas",
  components: {
      ThemaCard
  },
  mounted() {
    this.$store.dispatch('GET_CONTENT');
  },
  computed:  {
    themas(){
      return this.$store.state.themas
    }
  }
};

</script>
