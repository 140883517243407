<template>
  <div id="app">
    <Loader v-if="$route.name !== 'Login' && $store.state.isLoading" />
    <Nav v-if="$route.name !== 'Login'"  />
    <router-view/>
    <Notice v-if="$store.state.organisation.subdomain == 'thebe-extra' && $route.name !== 'Login'" />

    <Banner v-if="$route.name == 'Info'" />
    <Footer v-if="$route.name !== 'Login'" />
  </div>
</template>

<script>
import Nav from '@/components/Nav';
import Loader from '@/components/Loader';
import Footer from '@/components/Footer';
import Notice from '@/components/Notice';
import Banner from '@/components/Banner';

export default {
  name: 'App',
  components: {
    Nav,
    Footer,
    Loader,
    Notice,
    Banner
  },
  mounted(){
  //  alert('0')
  }
}
</script>

<style lang="scss">
@import '/assets/scss/app.scss';

</style>
