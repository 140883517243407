import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import elasticlunr from "elasticlunr";
import VuexPersistence from 'vuex-persist'



import router from "../router";

Vue.use(Vuex);

// const vuexLocal = new VuexPersistence({
//   storage: window.localStorage
// })


const vuexLocal = new VuexPersistence({
  reducer: (state) => ({authenticated: state.authenticated, favorites: state.favorites}),
})

export default new Vuex.Store({
  
  state: {
    authenticated: true,
    themas: [],
    content: [],
    favorites: [],
    organisation: [],
    themeslug: "educared-",
    searchIndex: null,
    wrongpassword: false,
    isLoading: false
  },
  mutations: {
    AUTHENTICATE_USER(state) {
      state.authenticated = true;
    },
    ADD_FAVORITE(state, payload) {
      state.favorites.push(payload.uid)
    },
    REMOVE_FAVORITE(state, payload) {
      state.favorites.splice(payload.index, 1);
    }
  },
  actions: {
    LOGIN(context, payload) {
      const password = payload.password;
      //  console.log(password, this.state.organisation)
      if (password && password === this.state.organisation.code) {
        this.state.wrongpassword = false;
       // this.commit('AUTHENTICATE_USER', )
        context.commit('AUTHENTICATE_USER');
        router.push("/");
      } else {
      
        this.state.wrongpassword = true;
        // return false;
      }
    },
    UPDATE_FAVS(context, payload) {
      const favIndex = context.state.favorites.indexOf(payload.uid);
      if (favIndex > -1) {
        //context.state.favorites.splice(favIndex, 1);
        context.commit('REMOVE_FAVORITE', {index: favIndex})
      } else {
        context.commit('ADD_FAVORITE', {uid: payload.uid})
       // context.state.favorites.push(payload.uid);
      }

      // let mrcFavorieten = localStorage.getItem("mrc-favorieten");
      // mrcFavorieten = JSON.parse(mrcFavorieten);
      // mrcFavorieten.splice(index, 1);
      // localStorage.setItem("mrc-favorieten", JSON.stringify(mrcFavorieten));
    },
    GET_CONTENT() {
      this.state.isLoading = true;
      axios
        .get(
          // `https://buddie-zelfzorg.educared.nl/!/BuddieApi/content`
          `https://buddie-zelfzorg.educared.nl/!/BuddieApi/content`
        )
        .then((response) => {
          this.state.themas = Object.values(response.data.themas);
          this.state.content = Object.values(response.data.content);

          this.dispatch("SETUP_SEARCHINDEX");

          this.state.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.state.isLoading = false;
        });
    },

    SETUP_SEARCHINDEX() {
      this.state.searchIndex = elasticlunr();
      this.state.searchIndex.addField("title");
      this.state.searchIndex.addField("search_meta");
      this.state.searchIndex.setRef("id");

      this.state.content.forEach((item) => {
        //  console.log(item)
        this.state.searchIndex.addDoc(item);
      });


    },
  },
  modules: {},
  plugins: [vuexLocal.plugin]
});
