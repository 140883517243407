import Vue from "vue";
import { Plugin } from "vue-fragment";
import axios from "axios";
// import { hexAToHSLA } from './utils/utils';

import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.use(Plugin);

Vue.config.productionTip = false;

//const subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
let subdomain, authenticated, organisation;

if (process.env.NODE_ENV === "development") {
  subdomain = "educared";
  authenticated = false;
} else {
  subdomain = window.location.host.split(".")[1]
    ? window.location.host.split(".")[0] === "www"
      ? "educared"
      : window.location.host.split(".")[0]
    : false;
  authenticated = false;
}

axios
  .get(
    `https://buddie-zelfzorg.educared.nl/!/BuddieApi/verify-organisation/${subdomain}`
  )
  .then((response) => {
    const data = response.data;
    organisation = data;
    let store = JSON.parse(localStorage.getItem("vuex"));
    if (!store) {
      store = { authenticated: false };
      // store.authenticated = false;
    }
    //  alert(store.authenticated)
    if (!data.has_auth || store.authenticated) {
      authenticated = true;
    }

    initializeVue();
  })
  .catch((error) => {
    console.log(error);
    // initializeVue()
  });

function initializeVue() {
  store.state.authenticated = authenticated;
  store.state.organisation = organisation;
  store.state.themeslug = `${subdomain}-`;
  window.app = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}
