<template>
  <fragment>
    <section :data-theme="`${$store.state.themeslug}soft`" class="padding-y-lg">
      <div class="container max-width-lg">
        <div class="grid grid-gap-sm">
          <div class="col-12">
            <div class="text-component margin-y-sm">
              <h4 class="color-primary">
                Mijn favorieten
              </h4>
            </div>
          </div>
          <div class="col-12" v-if="items.length < 1">
            <div class="text-component margin-top-sm">
              <h4 class="color-contrast-low text-center">
                Je hebt nog geen favoriete items
              </h4>
            </div>
            <svg
              class="margin-x-auto block favs--empty"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="489.004px"
              height="489.004px"
              viewBox="0 0 489.004 489.004"
              style="enable-background: new 0 0 489.004 489.004;"
              xml:space="preserve"
              fill="var(--color-contrast-low)"
            >
              <g>
                <g>
                  <path
                    d="M108.056,306.075L85.442,445.654c-3.467,21.409,9.873,31.82,29.797,23.252l122.221-52.571l122.324,52.325
			c19.943,8.531,32.524-2.242,28.107-23.477l-3.76-18.557h-7.713h-14.154V413.44v-36.981h-37.583h-15.224v-14.756v-59.776v-15.238
			h15.224h37.584V250.19v-13.669h14.154h58.735l29.321-27.022c16.016-14.63,11.693-29.747-9.647-33.617l-133.574-24.294
			L255.822,31.383c-10.371-19.05-27.156-19.048-37.489,0.024L153.14,151.703l-133.524,24.5
			c-21.337,3.913-25.987,19.306-10.387,34.377L108.056,306.075z"
                  />
                  <polygon
                    points="436.197,249.721 419.69,249.721 375.467,249.721 375.467,289.724 375.467,302.529 363.063,302.529 325.3,302.529 
			325.3,360.618 370.422,360.618 375.467,360.618 375.467,390.516 375.467,413.425 381.192,413.425 436.197,413.425 
			436.197,360.618 489.004,360.618 489.004,302.529 436.197,302.529 		"
                  />
                </g>
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          </div>
          <div :key="item.id" v-for="item in items" class="col-6 col-3@md">
            <ContentCard :uid="item.id" :data="item" />
          </div>
        </div>
      </div>
      <Modal />
    </section>
  </fragment>
</template>

<script>
// @ is an alias to /src

import ContentCard from "@/components/ContentCard.vue";
import Modal from "@/components/Modal.vue";

export default {
  name: "ThemaOverview",
  components: {
    ContentCard,
    Modal,
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
    items() {
      return this.$store.state.content.filter((item) => {
        return this.$store.state.favorites.includes(item.id);
      });
    },
  },
};
</script>
