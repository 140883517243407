<template>
  <div
    class="preloader flex flex-column justify-center items-center"
    :data-theme="`${$store.state.themeslug}dark`"
  >
    <h1 class="text-xxxl opacity-60%"><BuddieLogo /></h1>
    <div class="circle-loader circle-loader--v6" role="alert">
      <p class="circle-loader__label">Content is loading...</p>
      <div aria-hidden="true">
        <svg
          class="circle-loader__svg"
          width="48"
          height="48"
          viewBox="0 0 48 48"
        >
          <line
            class="circle-loader__fill"
            x1="6"
            y1="24"
            x2="42"
            y2="24"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-miterlimit="10"
            stroke-width="12"
          />
        </svg>
      </div>
    </div>
    <div class="text-component">
      <p>Laden...</p>
    </div>
  </div>
</template>

<script>
import BuddieLogo from "@/components/BuddieLogo";

export default {
  name: "Loader",
  components: {
    BuddieLogo,
  },
};
</script>
