import Vue from 'vue'
import VueRouter from 'vue-router'
import VueGtm from 'vue-gtm';
import store from '../store'
import Home from '../views/Home.vue'
import Info from '../views/Info.vue'
import Search from '../views/Search.vue'
import Login from '../views/Login.vue'
import ThemaOverview from '../views/ThemaOverview.vue';
import FavOverview from '../views/FavOverview.vue';
import DetailPage from '../views/DetailPage.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }, 
  {
    path: '/info',
    name: 'Info',
    component: Info
  }, 
  {
    path: '/zoeken/:query',
    name: 'Zoeken',
    component: Search,
    meta: { requiresAuth: true }
  },
  {
    path: '/item/:slug',
    name: 'Content',
    component: DetailPage,
    props: true,
    meta: { requiresAuth: true }
  },  
  {
    path: '/favorieten',
    name: 'Favorieten',
    component: FavOverview,
    meta: { requiresAuth: true }
  },
  {
    path: '/thema/:slug',
    name: 'Themaoverzicht',
    component: ThemaOverview,
    meta: { requiresAuth: true }
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

Vue.use(VueGtm, {
  id: 'GTM-MVTDGP2', // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
  // queryParams: { // Add url query string when load gtm.js with GTM ID (optional)
  //   gtm_auth:'AB7cDEf3GHIjkl-MnOP8qr',
  //   gtm_preview:'env-4',
  //   gtm_cookies_win:'x'
  // },
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional) 
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  //ignoredViews: ['homepage'] // If router, you can exclude some routes name (case insensitive) (optional)
});


// router.beforeEach((to, from, next) => {
  
//   if (to.name !== 'Login' && !store.state.authenticated) next({ name: 'Login' })
//   else next()
// })


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.authenticated) {
      if (store.state.organisation.subdomain == 'educared') {
        next({
          path: '/info',
        })
      } else {
        next({
          path: '/login',
        })
      }

    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
