<template>
  <footer class="footer-v4 padding-y-lg" :data-theme="`${$store.state.themeslug}dark`">
    <div class="container max-width-lg">
      <nav class="footer-v4__nav">
        <ul class="footer-v4__nav-list">
          <li
            class="footer-v4__nav-item"
            :key="thema.id"
            v-for="thema in themas"
          >
            <router-link
              :to="{
                name: 'Themaoverzicht',
                params: { slug: slugger(thema.title), title: thema.title },
              }"
              class="no-underline"
              >{{ thema.title }}</router-link
            >
          </li>
        </ul>
      </nav>

      <div class="footer-v4__logo">
        <router-link to="/">
        <h1 class="opacity-60%"><BuddieLogo /></h1>
          <!-- <figure>
            <img
              class="margin-bottom-sm margin-x-auto block animation--off"
              width="160"
              height="auto"
              :src="$store.state.organisation.logo"
            />
          </figure> -->
        </router-link>
      </div>

      <p class="footer-v4__print">
        &copy; Educared B.V. Alle rechten voorbehouden.<br />
        Buddie zorgt is een initiatief van Educared B.V. & Samen Beter Thuis
      </p>

      <div class="footer-v4__socials">
        <a
          href="https://www.linkedin.com/company/educared-slimmerleren/"
          target="_blank"
        >
          <svg
            class="icon"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 504.4 504.4"
            style="enable-background: new 0 0 504.4 504.4;"
            xml:space="preserve"
          >
            <g>
              <g>
                <path
                  d="M377.6,0.2H126.4C56.8,0.2,0,57,0,126.6v251.6c0,69.2,56.8,126,126.4,126H378c69.6,0,126.4-56.8,126.4-126.4V126.6 C504,57,447.2,0.2,377.6,0.2z M168,408.2H96v-208h72V408.2z M131.6,168.2c-20.4,0-36.8-16.4-36.8-36.8c0-20.4,16.4-36.8,36.8-36.8 c20.4,0,36.8,16.4,36.8,36.8C168,151.8,151.6,168.2,131.6,168.2z M408.4,408.2H408h-60V307.4c0-24.4-3.2-55.6-36.4-55.6 c-34,0-39.6,26.4-39.6,54v102.4h-60v-208h56v28h1.6c8.8-16,29.2-28.4,61.2-28.4c66,0,77.6,38,77.6,94.4V408.2z"
                ></path>
              </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import slugify from "slugify";
import BuddieLogo from "@/components/BuddieLogo";

export default {
  name: "Footer",
  components: {
    BuddieLogo
  },
  methods: {
    slugger(title) {
      return slugify(title, {lower: true});
    },
  },
  computed: {
    themas() {
      return this.$store.state.themas;
    },
  },
};
</script>
